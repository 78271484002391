import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/page-layout.js";
import { Link as GatsbyLink } from 'gatsby';
import { PostsPage } from '../components/posts-page';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Posts`}</h1>
    <PostsPage mdxType="PostsPage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      